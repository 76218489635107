import React from 'react';

function TopNav() {
    return (
        <header className="top-nav">
            <h1>CCLid</h1>
        </header>
    );
}

export default TopNav;
